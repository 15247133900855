<template>
    <div class="row">
        <div class="col-12 g">
            <div class="card card-body">
                <h4>
                    ما هي "رسائل SMS البديلة"؟
                </h4>
                <h5>
                    يمكنك تحديد ارسال الرسائل عن طريق الSMS اذا فشل ارسال الرسالة بالواتساب٫ سواء كان الرقم ليس عليه واتساب او فشل الارسال لأي سبب.
                </h5>
            </div>
        </div>
        <div class="col-12 col-lg-8 g c">
            <div class="card card-body">
                <div class="row">
                    <div class="col-12 col-lg-7">
                <div class="form-group">
                    <label for="">اختر البوابة</label>
                    <select class="form-control" v-model="portal">
                   <option value="">-- اختر البوابة --</option>
                   <option :value="p.code" v-for="p in portals" :key="p.code">{{ p.title }}</option>
                 </select>
                </div>
                <div class="form-group">
                    <label for="">اسم المستخدم</label>
                    <input type="text" class="form-control" v-model="username" aria-describedby="helpId" placeholder="">
                </div>
                <div class="form-group">
                    <label for="">كلمة المرور</label>
                    <input type="text" class="form-control" v-model="password" aria-describedby="helpId" placeholder="">
                </div>
                <div class="form-group">
                    <label for="">اسم المرسل</label>
                    <input type="text" class="form-control" v-model="sendername" aria-describedby="helpId" placeholder="">
                </div>
                </div>
               <div class="col-12 col-lg-5 g">
                 <div class="g">
                    <b-form-checkbox name="check-button" value="true" inline v-model="send_no_whatsapp">
                        ارسال SMS اذا كان الرقم ليس عليه واتساب
                    </b-form-checkbox>
                </div>
                <div class="g">
                    <b-form-checkbox name="check-button" value="true" inline v-model="send_on_error">
                        ارسال SMS اذا فشل الارسال لأي سبب
                    </b-form-checkbox>
                </div>

                <div class="col-12 g text-center">
                    <button class="btn btn-primary" @click="save()">
                    <i class="fa fa-save"></i>
                    حفظ الاعدادات
                </button>   <br><br>
                    "يتم تطبيق الاعدادات على الرسائل التي سوف ترسل مستقبلاً"
                </div>
               </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
export default {
    components: {
        BOverlay,
        BModal,
        BFormCheckbox,
    },
    data() {
        return {
            user: JSON.parse(localStorage.getItem("user")),
            portals: [],
            portal: "",
            username: "",
            password: "",
            sendername: "",
            send_no_whatsapp: "false",
            send_on_error: "false"
        }
    },
    created() {
        var g = this;
        $.post(api + "/user/messages/sms", {
                jwt: this.user.jwt,
            })
            .then(function(r) {
                g.portals = r.response.portals;
                g.username = r.response.sms.username;
                g.password = r.response.sms.password;
                g.sendername = r.response.sms.sendername;
                g.send_no_whatsapp = r.response.sms.send_no_whatsapp;
                g.send_on_error = r.response.sms.send_on_error;
                g.portal = r.response.sms.portal;
            })
            .catch(function() {
                g.loading = false;
                g.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "حدثت مشكلة في الاتصال",
                        icon: "EditIcon",
                        variant: "danger",
                    },
                });
            });
    },
    methods: {
        save() {
            var g = this;
            $.post(api + "/user/messages/save-sms", {
                    jwt: this.user.jwt,
                    portal: this.portal,
                    username: this.username,
                    password: this.password,
                    sendername: this.sendername,
                    send_on_error: this.send_on_error,
                    send_no_whatsapp: this.send_no_whatsapp
                })
                .then(function(r) {
                    if (r.status == 100) {
                        g.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "تم الحفظ",
                                icon: "CheckIcon",
                                variant: "success",
                            },
                        });
                    } else {
                        g.$toast({
                            component: ToastificationContent,
                            props: {
                                title: r.response,
                                icon: "EditIcon",
                                variant: "danger",
                            },
                        });
                    }
                })
                .catch(function() {
                    g.loading = false;
                    g.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "حدثت مشكلة في الاتصال",
                            icon: "EditIcon",
                            variant: "danger",
                        },
                    });
                });
        }
    }
}
</script>

<style>

</style>